import React from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { emailValidator, phoneValidator, nameValidator } from "../../../utils/Validators.js";

const UserForm = ({ handleSubmit, register, errors, phoneNumber, handleNextStep }) => (
  <form id="create-user" onSubmit={handleSubmit(handleNextStep)} style={{ width: "100%" }}>
    <Stack alignItems="center" justifyContent="center" width="fit-content" gap="10px" padding="0px">
      <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300" }}>Introduce los datos del usuario</Typography>
      <TextField required helperText={errors.name_first?.message} {...register("name_first", { validate: nameValidator })} variant="standard" sx={{ width: "100%" }} label="Nombre" />
      <TextField required helperText={errors.name_last?.message} {...register("name_last", { validate: nameValidator })} variant="standard" sx={{ width: "100%" }} label="Apellido" />
      <TextField helperText={errors.email?.message} {...register("email", { validate: emailValidator })} variant="standard" sx={{ width: "100%" }} label="Email" />
      <TextField
        required
        helperText={errors.phoneNumber?.message}
        {...register("phone_number", { validate: phoneValidator })}
        defaultValue={phoneNumber}
        variant="standard"
        sx={{ width: "100%" }}
        label="Teléfono"
      />
      <Button disabled={Object.keys(errors).length > 0} sx={{ width: "100%", marginTop: "20px" }} variant="contained" type="submit">
        Crear intervención
      </Button>
      <Button sx={{ width: "100%" }} onClick={() => handleNextStep(2)}>
        Volver
      </Button>
    </Stack>
  </form>
);

export default UserForm;
