import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import { Button, InputAdornment, Stack } from "@mui/material";
import { TextField } from "@mui/material";
import { useToast } from "../../../components/toastComponent/toast.Actions";
import { getStorageObject } from "../../../utils/LocalStorage";
import { postCustomCarcomponents } from "../../../services/API/Carcomponent.Services";

function createData(id, name, caducidad_meses, caducidad_km, price) {
  return {
    id,
    name,
    caducidad_meses,
    caducidad_km,
    price,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name", // Fecha
    numeric: false,
    disablePadding: false,
    label: "Componente",
  },
  {
    id: "email", // Vehículo
    numeric: false,
    disablePadding: false,
    label: "Caducidad km",
  },
  {
    id: "phone", // Matrícula
    numeric: false,
    disablePadding: false,
    label: "Caducidad meses",
  },
  {
    id: "precio", // Cliente
    numeric: false,
    disablePadding: false,
    label: "Precio",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "secondary.light" }}>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              <span style={{ color: "black", fontWeight: "200", fontSize: "1.05rem" }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CustomTableRow({ row, updatedCarcomponents, setUpdatedCarcomponents }) {
  const { openCustomToast } = useToast();

  const [caducidadKmState, setCaducidadKmState] = useState(row.caducidad_km);
  const [caducidadMesesState, setCaducidadMesesState] = useState(row.caducidad_meses);
  const [priceState, setPriceState] = useState(row.price);

  useEffect(() => {
    if (caducidadKmState === row.caducidad_km && caducidadMesesState === row.caducidad_meses && priceState === row.price) {
      return;
    }

    handleComponentChange();

    console.log(updatedCarcomponents);
  }, [priceState, caducidadKmState, caducidadMesesState]);

  const handleComponentChange = () => {
    if (caducidadKmState === "0" || caducidadMesesState === "0" || priceState === "0") {
      const deletedCarcomponents = updatedCarcomponents.filter((item) => item.id_carcomponent !== row.id);
      setUpdatedCarcomponents(deletedCarcomponents);
      return;
    }

    if (updatedCarcomponents && updatedCarcomponents.length > 0) {
      const foundCustomCarcomponent = updatedCarcomponents.find((item) => item.id_carcomponent === row.id);
      if (foundCustomCarcomponent) {
        const updatedComponents = updatedCarcomponents.map((item) =>
          item.id_carcomponent === row.id
            ? {
                ...item,
                id_carcomponent: row.id,
                price: priceState,
                caducidad_km: caducidadKmState,
                caducidad_meses: caducidadMesesState,
              }
            : item
        );
        setUpdatedCarcomponents(updatedComponents);
      } else {
        const newRow = {
          id_carcomponent: row.id,
          price: priceState,
          caducidad_km: caducidadKmState,
          caducidad_meses: caducidadMesesState,
        };
        setUpdatedCarcomponents([...updatedCarcomponents, newRow]);
      }
    } else {
      const newRow = {
        id_carcomponent: row.id,
        price: priceState,
        caducidad_km: caducidadKmState,
        caducidad_meses: caducidadMesesState,
      };
      setUpdatedCarcomponents([newRow]);
    }
  };

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: "pointer" }}>
        <TableCell padding="checkbox"></TableCell>
        <TableCell component="th" scope="row" padding="none">
          {row.name}
        </TableCell>
        <TableCell align="left">
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">kms</InputAdornment>,
            }}
            type="number"
            onChange={(e) => setCaducidadKmState(Number(e.target.value))}
            value={caducidadKmState}
          />
        </TableCell>
        <TableCell align="left">
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">meses</InputAdornment>,
            }}
            type="number"
            onChange={(e) => setCaducidadMesesState(Number(e.target.value))}
            value={caducidadMesesState}
          />
        </TableCell>
        <TableCell align="left">
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            type="number"
            onChange={(e) => setPriceState(Number(e.target.value))}
            value={priceState}
          />
        </TableCell>
      </TableRow>

      <TableRow sx={{ cursor: "pointer", marginLeft: "25px", padding: "0" }}>
        <TableCell style={{ backgroundColor: "#f1f1f1", paddingBottom: 0, paddingTop: 0, boxShadow: "inset 0px 20px 30px -20px rgba(0,0,0,0.1)" }} colSpan={6}></TableCell>
      </TableRow>
    </>
  );
}

export default function DataTable({ carcomponents, searchValue, setSearchValue }) {
  const rows = carcomponents.map((carcomponent) => {
    return createData(carcomponent.id, carcomponent.name, carcomponent.caducidad_meses, carcomponent.caducidad_km, carcomponent.price || 0);
  });
  const rowHeight = 60;

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("confirmed");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(carcomponents?.length || 24);
  const [updatedCarcomponents, setUpdatedCarcomponents] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setRowsPerPage(Math.floor(window.innerHeight / rowHeight) - 6);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSaveCustomCarcomponents = () => {
    const token = getStorageObject("token");
    const repairshopId = getStorageObject("repairShopId");

    postCustomCarcomponents(token, { carcomponents: updatedCarcomponents, id_user_repairshop: repairshopId }).then(() => {
      setUpdatedCarcomponents([]);
    });
  };

  const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box
      sx={{
        width: "800px",
      }}
    >
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "0 0 20px 0 rgba(0,0,0,0.14)" }}>
        <TableContainer>
          <Stack sx={{ flexDirection: "row", paddingRight: "20px", paddingLeft: "20px", justifyContent: "center", alignItems: "center" }}>
            <Typography sx={{ fontSize: "1.4rem", fontWeight: "200", padding: "30px" }}>Cambia tus valores por defecto</Typography>
          </Stack>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const open = false;
                return <CustomTableRow updatedCarcomponents={updatedCarcomponents} setUpdatedCarcomponents={setUpdatedCarcomponents} key={row.id} row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack sx={{ flexDirection: "row", paddingRight: "20px", paddingLeft: "20px", justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>
          <Button disabled={updatedCarcomponents.length === 0} onClick={handleSaveCustomCarcomponents} sx={{ width: "200px", margin: "20px" }} variant="contained">
            Guardar
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
}
